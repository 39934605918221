.google-visualization-table {
  max-width: 700px !important;
  margin-bottom: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
}
.google-visualization-table-tr-head {
  background-color: var(--secondary) !important;
}
.google-visualization-table-table {
  max-width: 700px !important;
  font-family: Vodafone, Roboto, sans-serif !important;
}

.google-visualization-table-table td {
  width: 500px;
  padding: 0.85rem !important;
  text-align: center;
}
.google-visualization-table-td.google-visualization-table-type-number {
  min-width: 16.65rem;
  text-align: center !important;
}
.google-visualization-table-table th {
  padding: 0.85rem 15px !important;
}

.google-visualization-table .gradient {
  background-image: none !important;
}
