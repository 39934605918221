$primary-color: #e60000;
$primary1-color: #4a4d4e;
$primary2-color: #25282b;
$secondary-color: #bebebe;
$secondary1-color: #00697c;
$light-color: #e2e2e2;
$success-color: #008a00;
$info-color: #005ea5;
$warning-color: #eb9700;
$white-color: #ffffff;
$dark-color: #0d0d0d;
$lightgrey: #ebebeb;
$focus-color: #0096ad;
$error-color: #bd0000;
$table-striped-color: #f2f2f2;
$card-background-color: #222;
$table-border-color: #dee2e6;
$nav-item-color: #404040;
$nav-hover-border: #a30000;
$shadow-color: rgba(0, 0, 0, 0.16);
$danger-color: rgb(129, 14, 14);
$invalid-color: rgba(255, 88, 88, 0.37);

//inverse colors
$primary-inverse-color: #ec2f2f;
$secondary1-inverse-color: #0096ad;
$warning-inverse-color: #eb9700;
$info-inverse-color: #5f9bc8;
$focus-inverse-color: #00b0ca;
$secondary-inverse-color: #7e7e7e;
$white-inverse-color: #262626;
$error-inverse-color: #f06666;
$switch-unchecked-color: #c7d1d1;

// utility colors
$code-color: #e83e8c;

// border
$border-radius: 6px;
$default-font-size: 1.3rem;
$description-text-font-size: 1.7rem; // 18px-20px
