/* You can add global styles to this file, and also import other style files */

@import "./variable.scss";
@import "./button.scss";
@import "./background.scss";
@import "./padding.scss";
@import "./borderColor.scss";
@import "./text.scss";
@import "./google-chart-table.scss";
@import "./mat-clock-picker.scss";

@keyframes tutorial-pulse-border {
  0% {
    border-radius: 10px;
    border: 6px dashed var(--info);
  }
  50% {
    border: 6px dashed transparent;
    border-radius: 10px;
  }
  100% {
    border: 6px dashed var(--info);
    border-radius: 10px;
  }
}

.tutorial-element {
  animation: tutorial-pulse-border 1.75s infinite ease-in-out;
  z-index: 9999 !important;
}

@keyframes pulse-text {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

.suggested-questions > ul > li > button > p {
  display: inline;
}

.generatedHtml {
  & p,
  & li,
  & ul,
  & ol {
    margin-bottom: 10px;
  }
  & ul,
  & li,
  & ol {
    list-style-type: disc;
  }

  & table {
    width: 100%;
    border-collapse: collapse;
    margin: 1.65rem 0;
    font-size: 1.2rem;
  }

  /* Table heading styles */
  & table thead th {
    background-color: #f2f2f2;
    color: #333;
    padding: 0.85rem;
    text-align: left;
    border-bottom: 2px solid #ddd;
  }

  /* Table body styles */
  & table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  & table tbody tr:nth-child(even) {
    background-color: #fff;
  }

  & table tbody td {
    padding: 0.85rem;
    border-bottom: 1px solid #ddd;
  }

  /* Optional: Add hover effect on rows */
  & table tbody tr:hover {
    background-color: #f1f1f1;
  }

  /* Optional: Additional styling for the table */
  & table caption {
    caption-side: top;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 0.85rem 0;
    color: #333;
  }

  & table th,
  .table td {
    text-align: left;
    padding: 0.65rem;
  }

  & table th {
    background-color: #eaeaea;
    color: #555;
  }
}

.panel-heading.panel-disabled {
  animation: pulse-text 3s infinite ease-in-out;
}

accordion > accordion-group .panel-heading.card-header.panel-enabled a[href].float-right {
  background: var(--success) !important;
  border-radius: 15px !important;
  color: white;
  transition: transform 0.3s ease; /* Smooth transition effect */
  &:hover {
    transform: scale(1.03);
  }
}

@media (max-width: 768px) {
  accordion > accordion-group .panel-heading.card-header.panel-enabled a[href].float-right {
    display: none;
  }
  .sticky-heading > h1 {
    font-size: 1.5rem;
    padding: 0 !important;
  }
}

code {
  display: inline-block;
  padding: 0.2em 0.4em;
  margin: 0 1.5px;
  font-size: 85%;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sticky-heading {
  position: sticky;
  padding-top: 0.35rem;
  top: 6rem;
  z-index: 2;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div,
  & > app-vcloudsmart-filter-simple {
    margin-top: 15px;
    min-width: 16.65rem;
  }
}

@media screen and (max-width: 768px) {
  .sticky-heading {
    flex-direction: column;
    & > app-vcloudsmart-filter-simple {
      display: block;
      margin: auto;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  50% {
    transform: scale(1.125);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}

img[src$="spinner_logo.svg"][alt="spinner"] {
  height: 200px;
  animation: pulse 2s infinite;
}

.iconbox .ng-fa-icon {
  cursor: pointer;
}

.btn.disabled {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
  cursor: pointer !important;
}

body {
  font-family: Vodafone, Roboto, sans-serif;
}

html {
  @media (min-width: 1250px) {
    font-size: 12.5px;
    line-height: 28px;
  }
  @media (min-width: 1000px) {
    font-size: 12px;
    line-height: 27px;
  }
  @media (min-width: 750px) {
    font-size: 11.5px;
    line-height: 26px;
  }
  @media (min-width: 500px) {
    font-size: 11px;
    line-height: 25px;
  }
  @media (max-width: 499px) {
    font-size: 10.5px;
    line-height: 24px;
  }

  font-weight: 500;

  ul {
    li {
      list-style: none;
    }
  }
}

img {
  max-width: 100%;
}

.responsive-table table {
  width: 100%;
}

.responsive-table table th {
  border: 1px solid #404040;
  border-right: none;
}

.responsive-table table th:last-child {
  border-right: 1px solid #404040;
}

.responsive-table table {
  width: 100%;
  border: 1px solid #404040;
}

.responsive-table td {
  font-size: 0.9rem;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
}

.modal-footer {
  border: none;
}

.modal-header .close {
  padding: 0.7rem 1rem;
}

.modal-header {
  border: none !important;
}

.table td {
  padding: 0.85rem;
  border: none;
}

input[type="date"] {
  position: relative;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
  font-size: 0.9rem;
}

input[type="date"]:after {
  font-weight: 900;
  color: #000;
  padding: 0 5px;
}

input[type="date"]:hover:after {
  color: var(--primary);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

input[type="date"]::-webkit-clear-button {
  z-index: 1;
}

.modal-title {
  font-weight: bold;
}

.modal-body form label {
  color: var(--dark);
}

.modal-body .form-group select {
  color: var(--secondary);
}

html,
body {
  min-height: 100vh;
}

.modal-header {
  background: var(--primary);
  border-radius: 0 !important;
  border-bottom: 1px solid var(--secondary) !important;
}

.modal-footer {
  border: none !important;
  border-top: 1px solid #1d1d1d !important;
  padding: 0.2rem 0.75rem;
}

.modal-content {
  border-radius: 0;
}

.border-none {
  border: 0 !important;
}

.modal-header .close {
  text-shadow: none;
  font-size: 1.3rem;
}

.modal-footer button:hover,
.modal-footer button {
  color: #fff !important;
  border-radius: $border-radius;
  font-weight: bold;
  font-size: 1.2rem;

  @media (max-width: 1250px) {
    font-size: 0.9rem;
    height: 25px;
  }
}

.modal-title {
  font-weight: bold;
  color: var(--dark);
  font-size: 1.35rem;

  @media (max-width: 1250px) {
    font-size: 1rem;
  }
}

h4.modal-title {
  color: #fff !important;
}

@media (min-width: 576px) {
  .custom-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .custom-container {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media (min-width: 900px) {
  .custom-container {
    max-width: 100%;
    padding: 0 50px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  .custom-container {
    max-width: 100%;
    padding: 0 70px;
  }
}

@media (min-width: 1400px) {
  .custom-container {
    padding: 0 80px;
  }
}

@media (min-width: 1660px) {
  .custom-container {
    padding: 0 115px;
  }

  // .container{
  //   min-width: min-content;
  // }
}

@media only screen and (max-width: 1250px) {
  .btn {
    padding-top: 2px;
    padding-bottom: 3px;
  }

  body {
    font-size: 0.9rem;
  }

  .modal-lg,
  .modal-xl {
    max-width: 585px;
  }

  .mrgn-1 {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .theme-btn,
  .btn-light {
    font-size: 0.9rem;
    line-height: 12px;
  }

  .btn.theme-btn:first-child {
    margin-left: 0 !important;
  }

  .responsive-table td {
    font-size: 0.95rem;
    padding: 1px 3px;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
    font-size: 1.15rem;
  }

  .form-group {
    margin-bottom: 5px;
  }

  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="time"] {
    -webkit-appearance: listbox;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    max-width: 29.15rem;
    margin: auto;
  }
}

@media only screen and (max-width: 755px) {
  .theme-btn,
  .btn-light {
    font-size: 0.9rem;
    line-height: 12px;
  }

  .responsive-table td {
    font-size: 0.95rem;
    padding: 1px 3px;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
    font-size: 1.15rem;
  }

  .form-group {
    margin-bottom: 5px;
  }

  .btn.theme-btn:first-child {
    margin-left: 0.25rem !important;
  }

  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="time"] {
    -webkit-appearance: listbox;
    font-size: 1.1rem;
  }
}

@font-face {
  font-family: "Vodafone";
  src: url("../fonts/vodafone_font_rg-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

.modal-header {
  margin: 0;
  padding-bottom: 5px;
  padding-left: 29px;
  padding-top: 0.85rem;

  @media (max-width: 1250px) {
    padding-left: 29px;
  }
}

.modal-content {
  border: none;
}

.modal-body form .form-group {
  margin-bottom: 10px;
}

.modal-body {
  padding-top: 5px;
}

.modal-header .close {
  outline: none;
}

.logo img {
  max-width: 25rem;
}

.header .logo img {
  max-width: 16.65rem;
}

.image-wrapper img {
  max-width: 450px;
}

.swal-title {
  font-size: 0.9rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.21);
  margin: 0 0 28px;
}

.swal2-popup .swal2-actions button {
  min-width: 120px;
  border-radius: $border-radius;
  font-weight: bold;
  font-size: 1.15rem;
  line-height: 19px;
  padding: 0.375rem 0.75rem;
  background-color: var(--primary);

  @media (max-width: 1250px) {
    min-width: 8.35rem;
    font-size: 0.9rem;
    height: 25px;
    padding-top: 2px;
    padding-bottom: 3px;
  }
}

.swal2-popup .swal2-title {
  font-size: 1.4rem;
  line-height: 30px;

  @media (max-width: 1250px) {
    font-size: 1.1rem;
    line-height: 24px;
  }
}

.swal2-popup .swal2-html-container {
  font-size: 1.1rem;

  @media (max-width: 1250px) {
    font-size: 1rem;
  }
}

.swal2-popup .swal2-icon .swal2-icon-content {
  font-size: 1.75em;
}

.swal2-popup {
  border-radius: 0 !important;
}

.btn-secondary {
  background-color: var(--secondary) !important;
}

.responsive-table thead th,
.responsive-table thead td {
  background-color: var(--dark);
  border-color: var(--dark) !important;
  color: #fff;
  border-left-color: var(--light) !important;
}

.responsive-table thead th:first-child {
  border-left: none !important;
}

.red {
  color: #ff0000;

  @media (max-width: 1250px) {
    line-height: 28px;
  }
}

.responsive-table table th {
  border: 1px solid #404040;
  border-right: none;
  font-size: 1.15rem;
  font-weight: 300 !important;
  padding-top: 0;
  vertical-align: middle;
  margin-top: 0;
}

@media only screen and (max-width: 1500px) {
  .table {
    td {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .table {
    td {
      font-size: 1rem;
    }
  }
}

.form-group.mfa {
  margin-top: 20px;
}

button.close {
  color: white;
  padding: 1.25rem 20px !important;
}

button.close:hover {
  color: white;
}

.spinner > div {
  background-color: var(--info);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right: 3px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* This class "pcs-typeahead" is used to create a custom typeahead input >> */
.pcs-typeahead .dropdown-item span {
  padding: 1.4rem 0;
  font-size: 1.4rem;
  border-bottom: 1px solid var(--secondary);
  display: inline-block;
  width: 100%;
}

.pcs-typeahead .dropdown-item {
  padding: 0 1.4rem;
}

.pcs-typeahead .dropdown-item.active,
.pcs-typeahead .dropdown-item:active,
.pcs-typeahead .dropdown-item:hover {
  background-color: var(--light);
  color: var(--dark);
}

.alert {
  margin: 0;
  font-size: 1.3rem;
}

.pcs-typeahead {
  width: 100%;
  position: relative;
  max-width: 750px;
}

.pcs-typeahead-input {
  cursor: pointer;
  border: 1px solid #262626;
  border-radius: $border-radius;
  padding: 0.65rem;
  font-size: 1.4rem;
  width: calc(100% - 2.5rem);
}

.pcs-typeahead svg {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.pcs-typeahead .dropdown {
  padding: 0;
  max-width: 750px;
  width: calc(100% - 2.5rem);
}

/* This class "pcs-typeahead" is used to create a custom typeahead input << */

/* This class "pcs-nav-tabs" is used to style tab selector from bootstrap >> */
.pcs-nav-tabs {
  // Common variables
  $tab-border-width: 2px;
  $arrow-size: 8px;
  $arrow-width: 5px;

  .nav-item {
    position: relative;
    color: #000;
    border-radius: $border-radius;

    .nav-link {
      height: 100%;
      font-size: 1.5rem;
      color: var(--dark);

      // Active state
      &.active {
        font-weight: bold;
        border-color: transparent;
        border-bottom: $tab-border-width solid var(--primary);

        &:after {
          content: "";
          position: absolute;
          bottom: -$arrow-size;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          border: {
            width: $arrow-size $arrow-width 0;
            style: solid;
            color: var(--primary) transparent;
          }
        }
      }

      // Hover and focus states
      &:hover,
      &:focus {
        font-weight: bold;
        outline: none;
        border: {
          top-color: transparent;
          left-color: transparent;
          right-color: transparent;
        }
      }
    }

    // Show state
    &.show .nav-link {
      border-color: transparent;
      border-bottom: $tab-border-width solid var(--primary);
    }
  }
}

/* This class "pcs-nav-tabs" is used to style tab selector from bootstrap << */

// pointer classes
.pointer {
  cursor: pointer !important;
}

.default-cursor {
  cursor: default !important;
}

.click-through {
  pointer-events: none;
}

// vcloud smart date picker styles
.bs-datepicker {
  border-radius: 3px;

  .bs-datepicker-container {
    padding: 0.85rem;
  }
}

.sticky-heading:has(.show) {
  z-index: 3;
}

.invalid-metadata-list {
  color: var(--$primary-color);
  line-height: 18px;
  list-style: disc;
  font-weight: normal;
}
