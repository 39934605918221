.text-primary {
  color: var(--primary) !important;
}

.text-danger {
  // color: var(--danger) !important;
  color: #dc143c !important;
}

.text-secondary {
  color: #262626;
}

.text-error {
  color: var(--error) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-white {
  color: $white-color;
}

//inverse

.text-primary-inverse {
  color: $white-color;
}

.text-secondary-inverse {
  color: #f2f2f2;
}

.text-error-inverse {
  color: $error-inverse-color;
}

.text-warning-inverse {
  color: $warning-inverse-color;
}

.text-info-inverse {
  color: $info-inverse-color;
}

.description-header-font-size {
  font-size: 2.5rem;
}

.description-text-font-size {
  font-size: 1.2rem;
}

.default-font-size {
  font-size: 1.5rem; //18px
  overflow: hidden;
}
