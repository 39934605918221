.border-error {
  border: 2px solid var(--error);
  border-radius: $border-radius;
}

.border-success {
  border: 2px solid var(--success);
  border-radius: $border-radius;
}

.border-info {
  border: 2px solid var(--info);
  border-radius: $border-radius;
}

.border-focus {
  border: 2px solid $focus-color;
  border-radius: $border-radius;
}

.border-warning {
  border: 2px solid var(--warning);
  border-radius: $border-radius;
}
.border-secondary {
  border: 2px solid var(--secondary);
  border-radius: $border-radius;
}

//inverse

.border-error-inverse {
  border: 2px solid $error-inverse-color;
  border-radius: $border-radius;
}
.border-info-inverse {
  border: 2px solid $info-inverse-color;
  border-radius: $border-radius;
}
.border-focus-inverse {
  border: 2px solid $focus-inverse-color;
  border-radius: $border-radius;
}
.border-info-warning {
  border: 2px solid $warning-inverse-color;
  border-radius: $border-radius;
}
