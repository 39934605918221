.btn {
  border-radius: $border-radius;

  .btn-sm {
    font-size: 0.8rem !important;
  }

  .btn-lg {
    font-size: 1.2rem !important;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &:hover {
    outline: none;
    border-color: transparent;
  }
}

.btn-primary,
.badge-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;

  &:hover {
    background-color: var(--primary) !important;
    border-color: var(--primary);
  }

  &:focus {
    background-color: var(--primary) !important;
    border: var(--primary) !important;
  }

  &:active {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

.btn-primary-outline {
  background-color: $white-color;
  border-color: var(--primary);
  color: var(--primary);
  font-size: 1.3rem;

  &:active {
    background-color: $white-color;
    border-color: var(--primary);
  }

  &:hover {
    background-color: $white-color;
    border-color: var(--primary);
    color: var(--primary);
  }
}

.btn-secondary,
.badge-secondary {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: black;

  &:hover {
    background-color: var(--secondary) !important;
    border-color: var(--secondary);
  }

  &:focus {
    background-color: var(--secondary) !important;
    border: var(--secondary) !important;
  }

  &:active {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
}

.btn-outline-secondary,
.badge-outline-secondary {
  color: black;
  border: 2px solid var(--secondary);

  &:hover {
    background-color: var(--secondary) !important;
    color: black;
  }

  &:focus {
    background-color: var(--secondary) !important;
  }

  &:active {
    background-color: var(--secondary) !important;
  }
}

.btn-outline-primary,
.badge-outline-primary {
  color: black;
  border: 2px solid var(--primary);

  &:hover {
    background-color: var(--primary) !important;
    color: $white-color;
  }

  &:focus {
    background-color: var(--primary) !important;
  }

  &:active {
    background-color: var(--primary) !important;
  }
}

.btn-danger,
.badge-danger {
  background-color: var(--danger);
  border-color: var(--danger);

  &:hover {
    background-color: var(--danger) !important;
    border-color: var(--danger);
  }

  &:focus {
    background-color: var(--danger) !important;
    border-color: var(--danger) !important;
  }

  &:active {
    background-color: var(--danger) !important;
    border-color: var(--danger) !important ;
  }
}

.btn-outline-danger,
.badge-outline-danger {
  border: 2px solid var(--danger);
  color: black;

  &:hover {
    background-color: var(--danger) !important;
    border-color: var(--danger);
    color: white;
  }

  &:focus {
    background-color: var(--danger) !important;
    border-color: var(--danger);
  }

  &:active {
    background-color: var(--danger) !important;
    border-color: var(--danger) !important;
  }
}

.btn-success,
.badge-success {
  background-color: var(--success);
  border-color: var(--success);

  &:hover {
    background-color: var(--success) !important;
    border-color: var(--success);
    color: white;
  }

  &:focus {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
  }

  &:active {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
  }
}

.btn-outline-success,
.badge-outline-success {
  border: 2px solid var(--success);
  color: black;

  &:hover {
    background-color: var(--success) !important;
    border-color: var(--success);
  }

  &:focus {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
  }

  &:active {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
  }
}

.btn-info,
.badge-info {
  background-color: var(--info);
  border-color: var(--info);

  &:hover {
    background-color: var(--info) !important;
    border-color: var(--info);
  }

  &:focus {
    background-color: var(--info) !important;
    border-color: var(--info) !important;
  }

  &:active {
    background-color: var(--info) !important;
    border-color: var(--info) !important;
  }
}

.btn-outline-info,
.badge-outline-info {
  border: 2px solid var(--info);
  color: black;

  &:hover {
    background-color: var(--info) !important;
    border-color: var(--info);
  }

  &:focus {
    background-color: var(--info) !important;
    border-color: var(--info);
  }

  &:active {
    background-color: var(--info) !important;
    border-color: var(--info) !important;
  }
}

.btn-light,
.badge-light {
  background-color: var(--light);
  border-color: var(--light);

  &:hover {
    background-color: var(--light) !important;
    border-color: var(--light);
  }

  &:focus {
    background-color: var(--light) !important;
    border-color: var(--light) !important;
  }

  &:active {
    background-color: var(--light) !important;
    border-color: var(--light) !important;
  }
}

.btn-outline-light,
.badge-outline-light {
  border: 2px solid var(--light);
  color: black;

  &:hover {
    background-color: var(--light) !important;
    border-color: var(--light);
  }

  &:focus {
    background-color: var(--light) !important;
    border-color: var(--light) !important;
  }

  &:active {
    background-color: var(--light) !important;
    border-color: var(--light) !important;
  }
}

.btn-warning,
.badge-warning {
  background-color: var(--warning);
  border-color: var(--warning);

  &:hover {
    background-color: var(--warning) !important;
    border-color: var(--warning);
  }

  &:focus {
    background-color: var(--warning) !important;
    border-color: var(--warning) !important;
  }

  &:active {
    background-color: var(--warning) !important;
    border-color: var(--warning) !important;
  }
}

.btn-outline-warning,
.badge-outline-warning {
  border: 2px solid var(--warning);
  color: black;

  &:hover {
    background-color: var(--warning) !important;
    border-color: var(--warning);
  }

  &:focus {
    background-color: var(--warning) !important;
    border-color: var(--warning) !important;
  }

  &:active {
    background-color: var(--warning) !important;
    border-color: var(--warning) !important;
  }
}

.btn-dark,
.badge-dark {
  background-color: var(--dark);
  border-color: var(--dark);

  &:hover {
    background-color: var(--dark) !important;
    border-color: var(--dark);
  }

  &:focus {
    background-color: var(--dark) !important;
    border-color: var(--dark) !important;
  }

  &:active {
    background-color: var(--dark) !important;
    border-color: var(--dark) !important;
  }
}

.btn-outline-dark,
.badge-outline-dark {
  border: 2px solid var(--dark);

  &:hover {
    background-color: var(--dark) !important;
    border-color: var(--dark);
  }

  &:focus {
    background-color: var(--dark) !important;
    border-color: var(--dark) !important;
  }

  &:active {
    background-color: var(--dark) !important;
    border-color: var(--dark) !important;
  }
}

// for every disabled property in btn class

.btn[disabled],
.badge[disabled] {
  opacity: 38%;
}
