@import "~@angular/cdk/overlay-prebuilt.css";

.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: #fff;
}

.mat-mini-fab.mat-primary {
  background-color: #3f51b5;
  color: #fff;
}

.mat-mini-fab .mat-button-wrapper {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  padding: 0.65rem 0;
  display: inline-block;
  line-height: 24px;
  font-size: 1.2rem;
  font-weight: 500;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  -webkit-user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 5.35rem;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  background: rgba(0, 0, 0, 0);
  color: #3f51b5;
  font-size: 1.2rem;
  font-weight: 500;
}

.mat-form-field {
  .mat-form-field-infix {
    width: auto;
  }
}
